import './BlogPage.scss'
import React from "react"
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { Link, graphql } from "gatsby"

const BlogPage = ({ data }) => {
    const { edges: posts } = data.allMdx
    return (
        <Layout>
            <SEO title="Blog"/>
            <h1>Awesome MDX Blog</h1>
            <ul>
                {posts.map(({ node: post }) => (
                    <li key={post.id}>
                        <Link to={post.fields.slug}>
                            <h4>{post.frontmatter.title}</h4>
                        </Link>
                        <p>{post.excerpt}</p>
                    </li>
                ))}
            </ul>
        </Layout>
    )
}
export const pageQuery = graphql`
  query blogIndex {
    allMdx {
      edges {
        node {
          id
          excerpt
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
export default BlogPage
